<template>
  <el-container class="design-container">
    <el-header class="design-header">
      <div class="flex">
        <div class="logo-box"></div>
        <!-- <Logo></Logo> -->
        <div class="header-tools">
          <!-- S 图层 -->
          <layer :value="layerList[faceIndex]" @change="layerAction">
            <div class="tools-item flex-col col-center">
              <span class="iconfont icon-chanpin"></span>
              <span>图层</span>
            </div>
          </layer>

          <div
            class="tools-item flex-col col-center"
            @click="editorAction('undo')"
          >
            <span class="iconfont icon-chexiao"></span>
            <span>撤销</span>
          </div>

          <!-- S 重做 -->
          <div
            class="tools-item flex-col col-center"
            @click="editorAction('redo')"
          >
            <span class="iconfont icon-zhongzuo"></span>
            <span>重做</span>
          </div>
          <!-- E 重做 -->

          <!-- S 背景 -->
          <color-picker @change="editorAction('setBackgroundColor', $event)">
            <div class="tools-item flex-col col-center">
              <span class="iconfont icon-background"></span>
              <span>背景</span>
            </div>
          </color-picker>
          <!-- E 背景 -->
          <!-- S 清空 -->
          <el-popconfirm
            icon="el-icon-info"
            icon-color="red"
            title="是否清空全部设计"
            @confirm="editor.clear()"
          >
            <div class="tools-item flex-col col-center" slot="reference">
              <span class="iconfont icon-clean"></span>
              <span>清空</span>
            </div>
          </el-popconfirm>
          <!-- E 清空 -->

          <!-- S 标尺 -->
          <div
            class="tools-item flex-col col-center"
            @click="rulerShow = !rulerShow"
          >
            <span class="iconfont icon-biaochi"></span>
            <span>标尺</span>
          </div>
          <!-- E 标尺 -->
          <shortcut-keys>
            <div slot="trigger" class="tools-item flex-col col-center">
              <span class="iconfont icon-jianpan1"></span>
              <span>快捷键</span>
            </div>
          </shortcut-keys>

          <div class="tools-item flex-col col-center">
            <span class="iconfont icon-lishi"></span>
            <span>记录</span>
          </div>
          <div class="tools-item flex-col col-center">
            <span class="iconfont icon-shuyi_shuoming"></span>
            <span>说明</span>
          </div>
        </div>
      </div>

      <div class="flex">
        <el-button type="primary" size="mini" @click="handleSave"
          >保存</el-button
        >
      </div>
    </el-header>
    <el-main class="design-content">
      <!-- 左侧区域 -->
      <div :class="`content--left ${leftBarShow && 'show-content'}`">
        <Menu @menuChange="changeMenu"></Menu>
        <div class="left-panel" v-show="leftBarShow">
          <div v-show="menuIndex == 0">
            <el-button type="primary" style="width: 100%">更多产品</el-button>
            <div class="product-info">
              <img :src="productInfo.image" class="product-img" alt="" />
              <div class="info-box">
                <div class="product-title">{{ productInfo.zh_cn_title }}</div>
                <div class="nr">￥{{ productInfo.price || 99 }}</div>
                <div class="primary product-detail-btn">产品详情</div>
              </div>
            </div>
            <spec
              :value="productInfo"
              @change="specChange"
              v-if="loaded"
            ></spec>
            <attr
              :value="productInfo"
              @change="attrChange"
              v-if="loaded"
            ></attr>
          </div>
          <div class="flex-col" v-show="menuIndex == 1">
            <div class="material-tabs">
              <div
                :class="materialIndex == 0 ? 'on' : ''"
                @click="materialIndex = 0"
              >
                官方素材
              </div>
              <div
                :class="materialIndex == 1 ? 'on' : ''"
                @click="materialIndex = 1"
              >
                我的素材
              </div>
            </div>
            <upload v-show="materialIndex == 1" @confirm="insertImage"></upload>
            <div class="material-manger">
              <material-box
                v-show="materialIndex == 1"
                :type="1"
                @choose="insertImage"
              >
                <el-button slot="trigger" type="primary" style="width: 100%"
                  >我的素材管理</el-button
                >
              </material-box>
            </div>
          </div>
        </div>
        <!-- 关闭按钮 -->
        <div
          :class="`close-btn left-btn ${leftBarShow && 'left-btn-open'}`"
          @click="leftBarShow = !leftBarShow"
        ></div>
      </div>
      <!-- 画布区域 -->
      <div id="workspace" class="content--center">
        <!-- 标尺 -->
        <div
          v-show="rulerShow"
          :style="{
            width: workspaceWidth + 'px',
            height: workspaceHeight + 'px',
          }"
          style="position: absolute"
        >
          <SketchRule
            :width="workspaceWidth"
            :height="workspaceHeight"
            :cornerActive="false"
            @handleLine="handleLine"
            :horLineArr="horLineArr"
            :verLineArr="verLineArr"
            :shadow="{ x: 0, y: 0, width: 0, height: 0 }"
            :palette="{
              bgColor: '#FFFFFF',
              longfgColor: '#7D8694',
              shortfgColor: '#7D8694',
              fontColor: '#7D8694',
              shadowColor: '#E8E8E8',
              lineColor: '#000000',
              borderColor: '#DADADC',
            }"
          ></SketchRule>
        </div>
        <div
          class="fast-profession"
          v-if="productInfo.fast_design_id || productInfo.father_id"
        >
          <div
            class="p-r-10 flex col-center"
            style="border-right: 1px solid #fff; cursor: pointer"
            @click.stop="setProduct(productInfo.father_id)"
            :style="{ opacity: productInfo.fast_design_id ? 1 : 0.47 }"
          >
            <span class="iconfont icon-zhuanyezhuanyeke m-r-5"></span>专业设计
          </div>
          <div
            class="p-l-10 flex col-center"
            @click.stop="setProduct(productInfo.fast_design_id)"
            style="cursor: pointer"
            :style="{ opacity: productInfo.father_id ? 1 : 0.47 }"
          >
            <span class="iconfont icon-kuaisu_jiasu m-r-5"></span>快速设计
          </div>
        </div>
        <div
          class="design-face-wrap flex flex-col"
          :style="{
            'max-height': canvasH - 50 + 'px',
            left: designFaceShiftX - 75 + 'px',
            top: designFaceShiftY + 'px',
          }"
        >
          <div
            class="design-face-item"
            v-for="(item, index) in designFaceData"
            :key="index"
            :class="faceIndex == index ? 'on' : ''"
            @click="faceIndex = index"
          >
            <el-tooltip
              effect="dark"
              :content="item.zh_cn_name"
              placement="top-start"
            >
              <el-button style="padding: 0">
                <img :src="item.img" alt="" />
              </el-button>
            </el-tooltip>
          </div>
        </div>
        <div class="img-wh-label" v-show="currentImgW && currentImgH">
          width：{{ currentImgW }} x height：{{ currentImgH }} (cm)
        </div>
        <div class="editor-canvas">
          <div
            class="editor-canvas-bg"
            :style="{
              width: canvasW * zoomScale + 'px',
              height: canvasH * zoomScale + 'px',
            }"
          ></div>
          <fabric-editor
            v-for="(item, index) in designFaceData"
            v-show="index == faceIndex"
            :key="index"
            :index="index"
            :value="item"
            :width="canvasW"
            :height="canvasH"
            :left="designFaceShiftX"
            :top="designFaceShiftY"
            ref="editor"
            @zoomChange="handleZoomChange"
            @confirm="setCanvas(index)"
            @change="editorObjAction($event)"
            @refresh="refreshTexture($event)"
          ></fabric-editor>
        </div>
        <div
          class="design-info flex-col"
          v-if="currentFace.recommend_size && currentFace.recommend_size[0]"
        >
          <div>
            推荐尺寸：{{ currentFace.recommend_size[0] }}px *
            {{ currentFace.recommend_size[1] }}px
          </div>
          <div class="flex col-center">
            <span>打印质量：</span>
            <span class="danger" v-if="currentFace.printquality == 'low'"
              >较差</span
            >
            <span class="success" v-else-if="currentFace.printquality == 'high'"
              >优秀</span
            >
          </div>
        </div>
        <div class="zoom-wrap">
          <Zoom @change="editorAction($event)"></Zoom>
        </div>
      </div>
      <!-- 右侧区域 -->
      <div class="content--right" v-show="rightBarShow">
        <div class="design-effect" v-if="loaded">
          <div class="type-btn" v-if="productInfo.is_three">
            <div
              class="type-btn-item btn-3d"
              :class="effectType == 3 ? 'on' : ''"
              @click="effectType = 3"
            >
              3D
            </div>
            <div
              class="type-btn-item btn-2d"
              :class="effectType == 2 ? 'on' : ''"
              @click="effectType = 2"
            >
              2D
            </div>
          </div>
          <div
            class="effect-preview"
            :style="{ 'z-index': effectType == 3 ? 99 : 98 }"
          >
            <effect
              ref="threeRef"
              :modelUrl="[productInfo.model_url]"
              :type="3"
              :id="99"
              :modelParams="modelParams"
            ></effect>
          </div>
          <div
            class="effect-preview"
            :style="{ 'z-index': effectType == 2 ? 99 : 98 }"
          >
            <big-preview :value="effectData">
              <div class="fangda" slot="trigger">
                <span class="iconfont icon-fangda1"></span>
              </div>
            </big-preview>

            <el-carousel
              :height="290 + 'px'"
              arrow="always"
              :autoplay="false"
              trigger="click"
            >
              <el-carousel-item
                v-for="(item, index) in effectData"
                :key="index"
                :name="item.design_name"
              >
                <effect
                  ref="effectRef"
                  :value="item"
                  :imgLayer="
                    currentSpecName ? item.imgLayer[currentSpecName] : 1
                  "
                  :modelUrl="
                    currentSpecName ? item.model_url[currentSpecName] : []
                  "
                  :id="index"
                  :type="2"
                  :images="currentSpecName ? item.images[currentSpecName] : []"
                  :modelParams="modelParams"
                  @success="handleEffectSuccess($event, index)"
                ></effect>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <object-operate
          @change="handleImageOperate"
          @replaceImage="replaceImage"
          :activeObj="activeObj"
        ></object-operate>
      </div>
      <!-- 关闭按钮 -->
      <div
        :class="`close-btn right-btn ${rightBarShow && 'right-btn-open'}`"
        @click="rightBarShow = !rightBarShow"
      ></div>
      <product-list :show="productShow"></product-list>
    </el-main>
  </el-container>
</template>
<script>
import Menu from "./components/menu.vue";
import Product from "./components/product/index.vue";
import ProductList from "./components/product/list.vue";
import Spec from "./components/product/spec.vue";
import Attr from "./components/product/attr.vue";
import FabricEditor from "./components/fabric-editor.vue";
import Zoom from "./components/zoom.vue";
import Upload from "./components/material/upload.vue";
import ObjectOperate from "./components/object-operate.vue";
import ShortcutKeys from "./components/shortcut-keys.vue";
import Effect from "./components/effect.vue";
import BigPreview from "./components/big-preview.vue";
import ColorPicker from "./components/color-picker.vue";
import Layer from "./components/layer.vue";
import MaterialBox from "./components/material/material-box.vue";
import SketchRule from "vue-sketch-ruler";

import { fabric } from "fabric";
import { v4 as uuidv4 } from "uuid";
import { deepClone } from "@/utils/util";
import { createJsonFile } from "@/utils/design/utils";
import {
  apiModelDetail,
  apiModelDesignPartsLists,
  apiModelEffectLists,
} from "@/api/model.ts";

export default {
  components: {
    Menu,
    Spec,
    ProductList,
    FabricEditor,
    Zoom,
    Upload,
    ObjectOperate,
    ShortcutKeys,
    Effect,
    BigPreview,
    ColorPicker,
    Attr,
    Layer,
    MaterialBox,
    SketchRule,
  },

  data() {
    return {
      loaded: false,
      menuIndex: 1,
      leftBarShow: true,
      rightBarShow: true,
      productShow: false,
      designType: 0,
      fast_design_id: 0,
      special_design_id: 0,
      materialIndex: 1,
      materialTabs: ["offical", "my"],

      workspaceWidth: 0,
      workspaceHeight: 0,
      canvasL: 0,
      canvasT: 0,
      designFaceShiftX: 0,
      designFaceShiftY: 0,
      canvasW: 650,
      canvasH: 650,
      zoomScale: 1,
      canvasArr: [],
      fabricCanvas: null,
      editor: null,
      effectRef: null,
      currentImgW: "",
      currentImgH: "",
      activeObj: {},
      productInfo: {},
      faceIndex: 0,
      faceName: "",
      currentFace: {},
      designFaceData: [],
      effectData: [{}],
      currentSpecName: "",
      modelParams: {},
      effectIndex: 0,
      effectType: 2,
      is3d: false,
      layerList: [],

      rulerShow: false,
      horLineArr: [],
      verLineArr: [],
      keysDialogVisible: false,
      historycolors: ["#000000", "#ffffff"],
      predefineColors: [
        "#E02020",
        "#FA6401",
        "#F7B500",
        "#6DD400",
        "#44D7B6",
        "#32C5FF",
        "#0091FF",
        "#6236FF",
        "#B729E0",
        "#6D7278",
        "#E5E5E5",
        "#BFBFBF",
        "#7F7F7F",
        "#262626",
        "#000000",
        "#FFFFFF",
      ],
      color: null,
    };
  },
  watch: {
    faceIndex: {
      handler(val) {
        this.editor = this.$refs.editor[val];
        this.fabricCanvas = this.$refs.editor[val].canvas;
        this.currentFace = this.designFaceData[val];
        this.faceName = this.designFaceData[val].design_name;
        if (this.currentFace.model_view) {
          const rotation = this.currentFace.model_view
            .split(",")
            .map((item) => Number(item) * 10);
          this.$refs.threeRef.moveCamera({
            x: rotation[0],
            y: rotation[1],
            z: rotation[2],
          });
        }
      },
    },
    effectIndex: {},
  },
  computed: {},
  methods: {
    handleLine(e) {
      this.horLineArr = e.h;
      this.verLineArr = e.v;
    },
    changeMenu(index) {
      if (index == 2) {
        this.insertText();
      } else {
        this.menuIndex = index;
      }
    },

    setCanvas(index) {
      if (index == 0) {
        this.faceIndex = index;
      }
    },

    handleZoomChange(value) {
      const workspaceElement = document.getElementById("workspace");
      this.zoomScale = value;
      if (workspaceElement) {
        this.designFaceShiftX =
          (workspaceElement.offsetWidth - this.canvasW * value) / 2;
        this.designFaceShiftY =
          (workspaceElement.offsetHeight - this.canvasH * value) / 2;
      }
    },

    async setProduct(product_id) {
      if (product_id == 0 || product_id == this.productInfo.id) {
        return;
      } else {
        this.effectData = [];
        this.designFaceData = [];
      }
      const productInfo = await apiModelDetail({ id: product_id });
      console.log(productInfo);
      this.productInfo = productInfo;
      if (this.productInfo.is_three && this.productInfo.model_url != "") {
        this.is3d = true;
      }
      this.currentSpecName =
        this.productInfo.spec_value_list[0]["spec_value_str"];
      await Promise.all([this.createDesignFaces(), this.createEffectDatas()]);
      this.editor = this.$refs.editor[0];
      this.fabricCanvas = this.$refs.editor[0].canvas;
      this.currentFace = this.designFaceData[0];
      this.faceName = this.designFaceData[0].design_name;
      this.loaded = true;
      setTimeout(() => {
        if (this.is3d) {
          this.$refs.threeRef.init();
        }
        for (const i in this.effectData) {
          this.$refs.effectRef[i].init();
        }
        setTimeout(async () => {
          await this.loadOldDesignDatas();
          this.refreshTexture();
        }, 500);
      }, 100);
    },

    createDesignFaces() {
      return new Promise(async (resolve, reject) => {
        let params = {
          model_id: this.productInfo.id,
          sort: "sort desc",
        };
        if (this.productInfo.spec_design) {
          params = { ...params };
        }
        const result = await apiModelDesignPartsLists(params);
        const designFaceList = result.lists;
        const faceData = [];
        const modelParams = {};
        for (const index in designFaceList) {
          const face = designFaceList[index];
          let cloneData = deepClone(face);
          if ([1, 3].indexOf(face.is_diy) > -1) {
            if (cloneData.more_spec == 1) {
              let sku_diy_data = JSON.parse(deepClone(cloneData.sku_diy_data));
              let currentData = sku_diy_data.find(
                (item) => item.name == this.currentSpecName
              );
              cloneData = { ...cloneData, ...currentData };
            }
            const {
              design_size,
              production_size,
              recommend_size,
              crop_size,
              dotted_size,
            } = cloneData;
            const designSize = design_size.split("|");
            cloneData.maxwidth = designSize[0];
            cloneData.maxheight = designSize[1];
            const productionSize = production_size.split("|");
            cloneData.productionWidth = productionSize[0];
            cloneData.productionHeight = productionSize[1];
            const dottedSize = dotted_size.split("|");
            cloneData.dottedWidth = dottedSize[0];
            cloneData.dottedHeight = dottedSize[1];
            cloneData.crop_size = crop_size.split("|");
            cloneData.printquality = "";
            cloneData.recommend_size = recommend_size
              ? recommend_size.split("x").map(Number)
              : ["", ""];
            faceData.push(cloneData);
            const modelData = deepClone(face);
            if (modelData.normalmap) {
              const normalmap = JSON.parse(modelData.normalmap);
              modelData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              modelData.normalMap = null;
            }
            modelData.default_texture = modelData.texture;
            modelParams[face.design_name] = modelData;
          } else {
            if (cloneData.normalmap) {
              const normalmap = JSON.parse(cloneData.normalmap);
              cloneData.normalMap = {
                img: normalmap.img,
                repeat: normalmap.repeat,
                scale: normalmap.scale,
              };
            } else {
              cloneData.normalMap = null;
            }
            modelParams[face.design_name] = cloneData;
          }
        }
        this.designFaceData = faceData;
        this.modelParams = modelParams;
        resolve(true);
      });
    },

    createEffectDatas() {
      return new Promise(async (resolve, reject) => {
        let params = {
          model_id: this.productInfo.id,
        };
        if (this.productInfo.spec_design) {
          params = { ...params, sort: "sort desc" };
        }
        const result = await apiModelEffectLists(params);
        const effectRes = result.lists;
        let effectArr = [];
        if (effectRes.length > 0) {
          for (const i in effectRes) {
            const item = deepClone(effectRes[i]);
            let images = {};
            let model_url = {};
            let imgLayer = {};
            if (item.more_spec == 1) {
              for (let skuData of JSON.parse(deepClone(item.sku_diy_data))) {
                images[skuData.name] = skuData.images;
                model_url[skuData.name] = skuData.model;
                imgLayer[skuData.name] = Number(skuData.img_layer);
              }
            } else {
              let imageTemp = deepClone(item.images)
                ? deepClone(item.images).split(",")
                : [];
              for (let spec of this.productInfo.spec_value_list) {
                images[spec.spec_value_str] = imageTemp;
                model_url[spec.spec_value_str] = JSON.parse(item.model_url);
                imgLayer[spec.spec_value_str] = Number(item.img_layer);
              }
            }
            item.images = images;
            item.model_url = model_url;
            item.imgLayer = imgLayer;
            item.currentImgs = images[this.currentSpecName];
            item.currentImgIndex = imgLayer[this.currentSpecName];
            item.preview_img = "";
            item.preview_loaded = false;
            effectArr.push(item);
          }
          this.effectData = effectArr;
        }
        resolve(true);
      });
    },

    editorAction(func, value) {
      this.editor[func](value);
      if (["big", "small", "auto"].indexOf(func) > -1) {
        return;
      }
      this.refreshTexture();
    },

    editorObjAction(value) {
      const obj = this.fabricCanvas.getActiveObject();
      if (!value) {
        return;
      }
      if (value.func == "change") {
        if (obj.type == "image") {
          this.editor.changeRepeat(obj, {
            type: obj.extraData.repeat.type,
            xpadding: obj.extraData.repeat.xpadding,
            ypadding: obj.extraData.repeat.ypadding,
          });
        }
      } else {
        if (value.func == "setImgWH") {
          this.currentImgW = value.value.width;
          this.currentImgH = value.value.height;
          return;
        } else if (value.func == "selectChange") {
          this.activeObj = obj;
          this.editor.setObjLabelInfo(obj, true, true);
          return;
        }
        this.editor[value.func](obj, value.value);
      }
      this.refreshTexture();
    },

    layerAction(value) {
      const id = value.value.id;
      const obj = this.fabricCanvas.getObject(`#${id}`);
      if (obj.length == 0) {
        return;
      }
      if (value.func == "selected") {
        this.fabricCanvas.setActiveObject(obj[0]);
      } else {
        this.editor[value.func](obj[0]);
        this.refreshTexture(true);
      }
    },

    handleImageOperate(value) {
      const obj = this.fabricCanvas.getActiveObject();
      this.editor[value.func](obj, value.value);
      this.fabricCanvas.requestRenderAll();
      this.editor.setObjLabelInfo(obj);
    },

    specChange(value) {
      //切换效果图
      this.currentSpecName = value.spec_value_str;
      for (let effect of this.effectData) {
        effect.currentImgs = effect.images[this.currentSpecName];
        effect.currentImgIndex = effect.imgLayer[this.currentSpecName];
      }
      //切换定制参数
      for (let index in this.designFaceData) {
        let face = deepClone(this.designFaceData[index]);
        if (face.more_spec == 1) {
          let sku_diy_data = JSON.parse(deepClone(face.sku_diy_data));
          let currentData = sku_diy_data.find(
            (item) => item.name == this.currentSpecName
          );
          face = { ...face, ...currentData };
          if (face.mask) {
            this.editor.setOverlayImage(face.mask);
          }
          this.$set(this.designFaceData, index, face);
        }
      }

      //切换模型颜色
      if (value.bind_action == 1) {
        let { bind_parts, color } = value;
        this.$refs.threeRef.setModelColor({
          name: bind_parts,
          color: color,
        });
      }
    },

    attrChange(value) {
      if (value.type == 1) {
        if (this.is3d) {
          for (const part of value.parts) {
            this.$refs.threeRef.setModelColor({
              name: part,
              color: value.value,
            });
          }
        }
        for (const part of value.parts) {
          this.$refs.effectRef[this.effectIndex].setModelColor({
            name: part,
            color: value.value,
          });
        }
      } else if (value.type == 3) {
        for (const part of value.parts) {
          this.$refs.threeRef.setNormalMap({
            name: part,
            img: value.value.image,
            repeat: value.value.normalmapRepeat,
            scale: value.value.normalmapScale,
          });
        }
      } else if (value.type == 4) {
      }
    },

    // 刷新图层
    refreshLayer(index = this.faceIndex) {
      return new Promise((resolve, reject) => {
        const canvas = this.fabricCanvas;
        const list = [
          ...canvas.getObjects().filter((item) => {
            // 过滤掉辅助线
            return !(item.id === "workspace" || item.name == "repeat");
          }),
        ]
          .reverse()
          .map((item) => {
            const {
              value,
              id,
              name,
              type,
              origin,
              show,
              lock,
              tagName,
              tagID,
            } = item.extraData;
            return {
              value,
              id,
              name,
              val: id,
              origin,
              type,
              show,
              lock,
              tagName,
              tagID,
              customized_id: item.id,
            };
          });
        this.$set(this.layerList, index, list);
        resolve();
      });
    },

    async refreshTexture(refreshLayer = false) {
      this.editor.saveState();
      let defaultBgColor = null;
      let defaultTexture = null;
      // 使用默认底色
      if (!this.fabricCanvas.backgroundColor && this.currentFace.bg_color) {
        defaultBgColor = this.currentFace.bg_color;
      }
      if (defaultBgColor) {
        this.fabricCanvas.setBackgroundColor(
          defaultBgColor,
          this.fabricCanvas.renderAll.bind(this.fabricCanvas)
        );
      }
      // 使用默认贴图
      if (this.currentFace.texture) {
        defaultTexture = this.currentFace.texture;
        await this.setDefaultTexture(defaultTexture);
      }
      const overlay = this.fabricCanvas.overlayImage;
      this.fabricCanvas.overlayImage = null;
      if (refreshLayer) {
        this.refreshLayer();
      }
      const scale = 800;
      const texture = this.fabricCanvas.toDataURL({
        left: this.designFaceShiftX,
        top: this.designFaceShiftY,
        width: this.canvasW * this.zoomScale,
        height: this.canvasH * this.zoomScale,
        multiplier: scale / (this.canvasW * this.zoomScale),
        format: "png",
      });
      if (this.is3d) {
        this.$refs.threeRef.setMapTexture({
          name: this.faceName,
          url: texture,
        });
      }
      for (let index in this.effectData) {
        let effect = this.effectData[index];
        if (effect.is_diy) {
          console.log(111);
          this.$refs.effectRef[index].setMapTexture({
            name: this.faceName,
            url: texture,
          });
        }
      }
      this.fabricCanvas.overlayImage = overlay;
      // 清除默认贴图
      if (defaultTexture) {
        this.fabricCanvas.setBackgroundImage(
          null,
          this.fabricCanvas.renderAll.bind(this.fabricCanvas)
        );
      }
      // 清除默认底色
      if (defaultBgColor) {
        this.fabricCanvas.setBackgroundColor(
          "",
          this.fabricCanvas.renderAll.bind(this.fabricCanvas)
        );
      }
    },

    setDefaultTexture(texture) {
      return new Promise((resolve, reject) => {
        fabric.Image.fromURL(texture, (img) => {
          this.fabricCanvas.setBackgroundImage(
            img,
            this.fabricCanvas.renderAll.bind(this.fabricCanvas),
            {
              scaleX: this.canvasW / img.width,
              scaleY: this.canvasH / img.height,
            }
          );
          resolve(true);
        });
      });
    },

    replaceImage(value) {
      const obj = this.fabricCanvas.getActiveObject();
      const image = new Image();
      image.onload = () => {
        const dottedwidth = Number(this.currentFace.maxwidth);
        const dottedheight = Number(this.currentFace.maxheight);
        let scaleVal = 1;
        let left = 0;
        let top = 0;
        let maxWidth = 0;
        let maxHeight = 0;
        if (dottedwidth && dottedheight) {
          const canvasCale = this.canvasW / dottedwidth;
          maxWidth = dottedwidth * canvasCale;
          maxHeight = dottedheight * canvasCale;
        } else {
          const canvasCale = 0.5;
          maxWidth = this.canvasW * canvasCale;
          maxHeight = this.canvasW * canvasCale;
        }
        if (maxWidth > maxHeight) {
          scaleVal = image.height > maxHeight ? maxHeight / image.height : 1;
          left = (this.canvasW - image.width * scaleVal) / 2;
          top = (this.canvasW - image.height * scaleVal) / 2;
        } else {
          scaleVal = image.width > maxWidth ? maxWidth / image.width : 1;
          left = (this.canvasW - image.width * scaleVal) / 2;
          top = (this.canvasW - image.height * scaleVal) / 2;
        }
        obj.setSrc(
          value.uri + "?x-oss-process=image/resize,w_1000",
          async () => {
            obj.extraData.src = value.uri;
            obj.extraData.value = value.uri;
            obj.extraData.name = value.name;
            obj.extraData.val = value.id;
            obj.left = left; // 图片相对画布的左侧距离
            obj.top = top; // 图片相对画布的顶部距离
            obj.scaleX = scaleVal.toFixed(6);
            obj.scaleY = scaleVal.toFixed(6);
            this.fabricCanvas.renderAll();
          },
          {
            crossOrigin: "anonymous",
          }
        );
      };
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = value.uri + "?x-oss-process=image/resize,w_1000";
    },

    insertImage(value) {
      const image = new Image();
      const id = uuidv4();
      image.onload = () => {
        const dottedwidth = Number(this.currentFace.maxwidth);
        const dottedheight = Number(this.currentFace.maxheight);
        let scaleVal = 1;
        let left = 0;
        let top = 0;
        let maxWidth = 0;
        let maxHeight = 0;
        if (dottedwidth && dottedheight) {
          const canvasCale = this.canvasW / dottedwidth;
          maxWidth = dottedwidth * canvasCale;
          maxHeight = dottedheight * canvasCale;
        } else {
          const canvasCale = 0.5;
          maxWidth = this.canvasW * canvasCale;
          maxHeight = this.canvasW * canvasCale;
        }
        if (maxWidth > maxHeight) {
          scaleVal = image.height > maxHeight ? maxHeight / image.height : 1;
          left = (this.canvasW - image.width * scaleVal) / 2;
          top = (this.canvasW - image.height * scaleVal) / 2;
        } else {
          scaleVal = image.width > maxWidth ? maxWidth / image.width : 1;
          left = (this.canvasW - image.width * scaleVal) / 2;
          top = (this.canvasW - image.height * scaleVal) / 2;
        }
        const fabricObject = new fabric.Image(image, {
          id: id,
          type: "image",
          name: "customize",
          left: left, // 图片相对画布的左侧距离
          top: top, // 图片相对画布的顶部距离
          scaleX: scaleVal.toFixed(6),
          scaleY: scaleVal.toFixed(6),
          preserveObjectStacking: true,
          extraData: {
            src: value.uri,
            name: value.name,
            value: value.uri,
            val: value.id,
            id: id,
            type: "image",
            // name: value.name,
            show: true,
            lock: false,
            tp: 2,
            width: image.width,
            height: image.height,
            originScale: value.width / image.width,
            tagID: null,
            tagName: "无",
            repeat: {
              type: 0,
              xpadding: 0,
              ypadding: 0,
              show: false,
              src: "",
            },
          },
        });

        this.fabricCanvas.add(fabricObject).setActiveObject(fabricObject);
        // let clipPath = this.editor.createClippath();
        // this.fabricCanvas.clipPath = clipPath;
        // this.fabricCanvas.requestRenderAll();
      };
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = value.uri + "?x-oss-process=image/resize,w_1000";
    },

    insertText(text = "TEXT") {
      const id = uuidv4();
      const fabricObject = new fabric.Text(text, {
        name: "customize",
        id: id,
        type: "text",
        left: 0, //
        top: 0, //
        fill: "#000",
        textAlign: "center",
        fontfamily: "Arial",
        fontSize: 30,
        stroke: "#000",
        strokeWidth: 0,
        // selectable: true, //禁止双击编辑
        // preserveObjectStacking: true,
        extraData: {
          id: id,
          type: "text",
          src: "",
          name: text,
          value: text,
          val: "",
          show: true,
          lock: false,
          tp: 2,
          zIndex: id,
          vertical: false,
          tagID: null,
          tagName: "无",
          repeat: {
            type: 0,
            xpadding: 0,
            ypadding: 0,
            show: false,
            src: "",
          },
        },
      });
      // 使文本框在画布内水平和垂直居中
      fabricObject.set({
        left: (this.canvasW - fabricObject.width) / 2,
        top: (this.canvasH - fabricObject.height) / 2,
      });
      this.fabricCanvas.add(fabricObject).setActiveObject(fabricObject);
    },

    handleEffectSuccess(value, index) {
      this.effectData[index].bigimg = value;
    },

    handleSave() {
      let json = [];
      for (let index in this.designFaceData) {
        json.push(
          this.$refs.editor[index].canvas.toJSON(["extraData", "id", "name"])
        );
      }
      createJsonFile(JSON.stringify(json));
    },

    loadOldDesignDatas() {
      return new Promise((resolve, reject) => {
        fetch("https://fqsaas.oss-cn-shanghai.aliyuncs.com/test-json.json")
          .then((response) => response.json())
          .then((res) => {
            let length = 0;
            for (let index in res) {
              let canvas = this.$refs.editor[index].canvas;
              canvas.loadFromJSON(res[index], function () {
                // 在这里可以执行特定操作，比如调整画布大小或添加事件监听器等
                canvas.renderAll();
                length += 1;
                if (length == res.length) {
                  resolve(true);
                }
              });
            }
          });
      });
    },
  },
  mounted() {
    const workspaceElement = document.getElementById("workspace");
    if (workspaceElement) {
      this.workspaceWidth = workspaceElement.offsetWidth;
      this.workspaceHeight = workspaceElement.offsetHeight;
    }
    // this.canvasL = (this.workspaceWidth - this.canvasW) / 2;
    // this.canvasT = (this.workspaceHeight - this.canvasH) / 2;
    this.setProduct(this.$route.query.id || 15);
  },
};
</script>
