<template>
  <el-popover
    ref="layerpopover"
    placement="top"
    v-model="visible"
    width="360"
    trigger="manual"
    class="popover-input"
  >
    <div class="flex-col">
      <div class="nr">画布图层</div>
      <div class="layer-content">
        <div
          class="layer-item"
          v-for="(item, index) in list"
          :key="index"
          @click="setLayerActive(index, item)"
          :class="[activeIndex == index ? 'on' : '']"
        >
          <div class="layer-item--left flex col-center">
            <img
              :src="item.value + '?x-oss-process=image/resize,w_50,h_50'"
              v-if="item.type == 'image'"
              alt=""
            />
            <img src="" :src="writingIcon" v-else alt="" />
            <span class="layer-name">{{ item.name }}</span>
          </div>
          <div class="layer-item--right">
            <div class="layer-tool">
              <span
                :class="`iconfont icon-${item.show ? 'eye' : 'no_eye'} `"
                @click="handleObjectAction('layerShowOrHide', item, index)"
              ></span>
              <span
                @click="handleObjectAction('layermoveup', item, index)"
                v-if="index != 0"
                class="iconfont icon-jiantou_xiangshang"
              ></span>
              <span
                @click="handleObjectAction('layermovedown', item, index)"
                v-if="index + 1 != list.length"
                class="iconfont icon-jiantou_xiangxia"
              ></span>

              <span
                :class="`iconfont icon-${item.lock ? 'lock-fill' : 'unlock'} `"
                @click="handleObjectAction('layerLock', item, index)"
              ></span>
              <span
                :class="`iconfont icon-delete`"
                @click="handleObjectAction('deleteObject', item, index)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="inline" type="text" slot="reference" @click="handleOpen">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
export default {
  components: {},
  props: {
    value: {
      type: Array,
    },
  },
  data() {
    return {
      visible: false,
      list: [],
      activeIndex: 0,
      writingIcon:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAABC5JREFUeF7tm11y4yAMgE1+7tG+ZSbOGdqcJM1J2pyk2ZM0PUOcmb4194gd1vKAB7tgBCKY7NYv7bQ2SB+SECBYNsKzWCweoNvZbNb8hKcoisMIomTslp2CokLJpyzLnjnnD4yxVul+35zzM/yNMXaG3yeTyefxeNzfUsbgAITSG6HsC1V4AeVwKxjBAIDi0+n0tR49stJD0Gqw+6qqdl9fX421UB8yADnitSBvVGGw34NV1K60L8vyDxWEN4AxFNfFDABRFMUOC6//nhcAofy3b6ehvwOLqKpq7WMNzgDyPH/OsuwjtBLU9qRbuFqDE4A8z19j+ronlDcXCGgAeZ7DqMPoJ//ATHE6nbYYQVEA7kl5qTQWghXAnZi9abCt7jAIINWAhzFt+Q5jbDuUThsB/AvKAwQxO2xNiy0jgOVy+T20cHEZhbHfBQin0+lRJ4cWwJ37vZa3KSiaAPCxRy10/6Zs8QeA5XL5fusVXWjlsO3prKADILUcH6sY9j1dQOwAICQ8h6Io1lhBqO+JgXr3zEw7svYB+Pp+VAAA0Hew+jNCC2C1Wr1wzoGqzxMMAIwuZlnrC0Aot5Z5QQuAGPx+AJBbZGCmMp8QG507U2amKqVskGo3PIgAWnlbAPXc72v+ALUDYCiLNM3HQwFYl8hQAKjtNQCI5t8BYEuhfQCIlLazxKUAUN2gAUA0/w4AWwrtCwA6URc2VABSjgYAtTHpAhhLogBQTTeUzNICqAufJgZghKIAUF0B09fQdCZhSgugBMBmySlM1HjsJYUJAAD6OlDT9aAAXBIHKgCXvmzv1punjMXO/1MCkGXZmtmmLRtF1///AjBsWce2RJkL/NcuUB+uPv4CEIkQaRp0iQMpxYBmFhCpMDURQjNIBUA/D4h27pcKAJm+y0ww2qlvKgD6i6FoZ/6pAJAry8YCYs7BqQCAANisX2T0oq6usFEwBQCqDCqAKG6QAgB1Y6UFEMsNUgAAGaDceQ51MIL1ANg70JavjDUA0Y/Gxgagjn4nCMohDLBBarMGbdlKDAuwHo5GmBIHT5Awm6o2ukP/74++1gLgjyEFcS1uVkrsQRQosw9Vg6y1PG2BhDjW+ghRIgNTzuVy8boMMZ/P4Y6B73llawzOJTIRXIFiyT7ftoeh/Y8Hy+RCuoKP1IG+MSpvjAFqx3deMEUrlFTWCdGWy4FGHZpB1SxYS2WVeLAJGJED6qltCqU8ygXu0B2sZq/qhLKAHoQoq0ZPExkMeLo2nQFIl5hOp0HyBE9FO5/Z6oGH+vACkEpc8L0mQ3KBPs0Rb485+brJCrwtYEQQh7Ist5hSOox7BQMgOwOLgBz+er0+UYsYoE1h5k1RhMtlKIzyztMgtlEVBlye5pxvlIWV8eKVenkaFK7P7z9vfas8uAVgIPWvz5dleQ5l0pj+1Xf+AstYJOqpml+fAAAAAElFTkSuQmCC",
    };
  },
  watch: {
    value: {
      handler(val) {
        this.list = val;
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleOpen() {
      this.visible = !this.visible;
    },
    close() {
      this.visible = false;
    },
    handleObjectAction(action, v, n) {
      this.$emit("change", { func: action, value: v });
    },

    handleClickOutside(event) {
      // 检查点击的区域是否在弹出框之外
      if (
        this.$refs.layerpopover &&
        !this.$refs.layerpopover.$el.contains(event.target)
      ) {
        this.visible = false;
      }
    },

    setLayerActive(index, v) {
      this.activeIndex = index;
      this.$emit("change", { func: "selected", value: v });
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
};
</script>
<style lang="scss" scoped>
.layer-content {
  width: 330px;
  max-height: 405px;
  box-sizing: border-box;
  margin-top: 6px;

  .layer-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
    box-sizing: border-box;
    background-color: #f8f8f8;
    width: 330px;
    padding: 8px 5px;
    box-sizing: border-box;
    margin-bottom: 8px;
    border-radius: 5px;
    cursor: pointer;
    &.on {
      border: 1px solid $--color-primary;
    }
    .layer-item--left {
      > img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid #495b6e;
        cursor: move;
      }
      .layer-name {
        margin-left: 5px;
        width: 145px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .layer-item--right {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .layer-tool {
        > span {
          margin: 0 3px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
