import ExifReader from "exifreader";
import md5 from "js-md5";
import OSS from "ali-oss";
import { MessageBox, Message } from "element-ui";

export const confirm = (
  content,
  btnType = "warning",
  confirmButtonText = "sure",
  cancelButtonText = "cancel"
) => {
  return new Promise((resolve, reject) => {
    MessageBox.confirm(content, {
      confirmButtonText: "确认",
      cancelButtonText: "取消",
      type: btnType,
    })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        reject(true);
      });
  });
};

export const preUpload = (file) => {
  return new Promise(async (resolve, reject) => {
    const size = file.size;
    if (size > 30 * 1024 * 1024) {
      Message.error("文件不能超过30M");
      return;
    }
    let suffix = "jpg";
    const filename = file.name;
    if (filename.indexOf(".psd") > -1) {
      suffix = "psd";
    } else if (filename.indexOf(".png") > -1) {
      suffix = "png";
    }
    const imgmode = await checkImageBeforeUpload(file);
    if (imgmode == "CMYK") {
      const image = await uploadCMYKImage(
        file,
        `${getCurrentTime()}/${md5Str(file.name)}.${suffix}`
      );
    }
  });
};

export const getBaseURL = () => {
  const params = parseQueryString();
  let baseURL = "";
  const urlPrefix = params.platform == "admin" ? "adminapi" : "shopapi";
  if (params.platform == "admin") {
    baseURL = `https://${parseQueryString().url}/`;
  } else {
    baseURL = `https://${parseQueryString().url}/`;
  }
  return { baseURL, urlPrefix };
};

export const parseQueryString = () => {
  // 获取查询字符串
  const queryString = window.location.search.substring(1);

  // 初始化一个空对象来存储参数
  const params = {};

  // 如果查询字符串存在，解析它
  if (queryString) {
    // 分割字符串成键值对数组
    const pairs = queryString.split("&");

    for (const pair of pairs) {
      // 分离键和值
      const [key, value] = pair.split("=");

      // 解码 URI 组件以避免编码问题
      const decodedKey = decodeURIComponent(key);
      const decodedValue = decodeURIComponent(value);
      // 将键值对添加到 params 对象中
      params[decodedKey] = decodedValue;
    }
  }

  return params;
};

export const getImageOssUrl = (url, size) => {
  return `${url}?x-oss-process=image/resize,w_${size},w_${size}`;
};

export const checkImageBeforeUpload = (file) => {
  return new Promise(async (resolve, reject) => {
    const imgmode = await getImageMode(file);
    if (imgmode == "CMYK") {
      const res = await confirm(
        "检测到当前图片为CMYK模式，上传会自动转为RGB模式同时会造成颜色误差，是否确认继续上传？"
      );
      if (!res) {
        reject(false);
      }
    }
    resolve(imgmode);
  });
};

export const uploadCMYKImage = (file, filename) => {
  return new Promise(async (resolve, reject) => {
    // 创建一个FormData对象来发送文件
    const formData = new FormData();
    formData.append("image", file);
    formData.append("filename", filename);
    // 发送POST请求到后端服务器进行图片处理
    fetch("https://image.fqwlkj.cn/cmyk", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then(async (result) => {
        const img = await getOssFileInfo({
          name: file.name,
          size: file.size,
          url: result.data,
        });
        resolve(img);
      });
  });
};

export const uploadToOss = (file, filename, callback) => {
  return new Promise((resolve, reject) => {
    const client = new OSS({
      accessKeyId: "LTAI4FyKmzTLUN7G8LuirXnB",
      accessKeySecret: "I2eSRxPcRFVzEFKaYgaBSnD1dON7Lv",
      bucket: "fqsaas",
      endpoint: "oss-cn-shanghai.aliyuncs.com",
      region: "oss-cn-shanghai",
      domain: "https://img.shopape.cn",
      ossdomain: "https://fqsaas.oss-cn-shanghai.aliyuncs.com",
    });
    client.multipartUpload(filename, file, {
      progress: (percentage, checkpoint) => {
        callback && callback(Math.round(percentage * 100));
        if (percentage == 1) {
          getOssFileInfo({
            name: file.name,
            size: file.size,
            url: "https://fqsaas.oss-cn-shanghai.aliyuncs.com" + "/" + filename,
          }).then((res) => {
            resolve(res);
          });
        }
      },
    });
  });
};

export const getOssFileInfo = (file) => {
  return new Promise((resolve, reject) => {
    fetch(`${file.url}?x-oss-process=image/info`)
      .then((response) => response.json())
      .then((json) => {
        const img = {};
        img.name = file.name;
        img.url = file.url;
        img.size = file.size;
        img.width = json.ImageWidth.value;
        img.height = json.ImageHeight.value;
        img.format = json.Format.value;
        resolve(img);
      });
  });
};

export const randomString = (length, type = 1) => {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  switch (type) {
    case 1:
      characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      break;
    case 2:
      characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      break;
    case 3:
      characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      break;
  }
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const md5Str = (str) => {
  return md5(Date.now() + str);
};

export function getAssetsFile(filePath) {
  // return new URL(`../assets/${filePath}`, import.meta.url).href;
}

export const getCurrentTime = (type) => {
  var date = new Date(); // 当前时间
  var year = date.getFullYear(); // 年
  var month = repair(date.getMonth() + 1); // 月
  var day = repair(date.getDate()); // 日
  var hour = repair(date.getHours()); // 时
  var minute = repair(date.getMinutes()); // 分
  var second = repair(date.getSeconds()); // 秒
  // 当前时间
  var curTime = "";
  switch (type) {
    case 1:
      curTime = `${year}${month}${day}`;
      break;
    case 2:
      curTime = `${year}${month}`;
      break;
    default:
      curTime = `${year}${month}${day}`;
      break;
  }
  return curTime;
  function repair(i) {
    if (i >= 0 && i <= 9) {
      return "0" + i;
    } else {
      return i;
    }
  }
};

export const filterURLSpecialChars = (url) => {
  // 使用正则表达式匹配并替换特殊字符
  // 这个正则表达式匹配空格、正斜杠、问号、百分号、井号、和号、等号以及非ASCII字符
  const specialCharsRegex = /[ %\/?:&=#+\-]/g;

  // 使用replace方法替换匹配到的特殊字符
  // 这里用空字符串替换它们，实际上你可以替换为任何你想要的字符
  const filteredURL = url.replace(specialCharsRegex, "");

  return filteredURL;
};

export const getImageMode = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const arrayBuffer = event.target.result;
      const metadata = ExifReader.load(arrayBuffer, { expanded: true });
      let mode = "RGB";
      if (metadata.file["Color Components"]) {
        const colorComponents = metadata.file["Color Components"].value;
        switch (colorComponents) {
          case 3:
            mode = "RGB";
            break;
          case 4:
            mode = "CMYK";
            break;
          default:
            mode = "RGB";
        }
      }
      resolve(mode);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const createJsonFile = (jsonString, filename) => {
  const blob = new Blob([jsonString], { type: "application/json" });
  const file = new File([blob], filename, { type: "application/json" });
  uploadToOss(file, "test-json.json");
};
