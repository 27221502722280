<template>
  <div>
    <el-divider content-position="left">规格</el-divider>
    <div class="spec-wrap">
      <div
        class="flex-col mb-10"
        v-for="(specItem, specIndex) in specValue"
        :key="specIndex"
      >
        <div class="spec-title">
          {{ specItem.name == "Color" ? "颜色" : "尺码" }}
        </div>
        <div class="flex flex-wrap" v-if="specItem.name == 'Color'">
          <div
            v-for="(optionItem, optionIndex) in specItem.spec_list"
            :key="optionIndex"
          >
            <el-tooltip
              class="item"
              effect="dark"
              :content="optionItem.value"
              placement="bottom"
              ><div
                :class="[
                  'spec-color',
                  specCheckedIds[specIndex] == optionItem.id ? 'selected' : '',
                ]"
                :style="{ background: optionItem.color }"
                @click="shooseSpec(optionItem, specIndex)"
              ></div
            ></el-tooltip>
          </div>
        </div>
        <div class="flex flex-wrap" v-if="specItem.name == 'Size'">
          <div
            v-for="(optionItem, optionIndex) in specItem.spec_list"
            :key="optionIndex"
          >
            <el-button
              :type="
                specCheckedIds[specIndex] == optionItem.id ? 'primary' : ''
              "
              class="spec-item"
              size="mini"
              round
              @click="shooseSpec(optionItem, specIndex)"
            >
              {{ optionItem.value }}</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
    },
  },

  data() {
    return {
      specValue: [],
      specCheckedIds: [],
      specValueList: [],
      color: "",
      specMapTable: new Map(), // 规格值映射表
      specCheckedList: [],
    };
  },
  computed: {
    specCheckedColor() {
      return this.specCheckedList
        .map((item) => item.color)
        .filter((item) => item)
        .join(",");
    },
  },
  watch: {
    value: {
      handler(val) {
        this.specValue = val.spec_value;
        this.specValueList = val.spec_value_list;
        if (val.spec_value_list) {
          this.specCheckedIds = val.spec_value_list[0]["spec_value_ids"]
            .split(",")
            .map(Number);
        }
      },
      immediate: true,
      deep: true,
    },
    specCheckedIds: {
      handler(value) {
        if (value.length > 0) {
          const currentSpec = this.specValueList.find(
            (spec) => spec.spec_value_ids == this.specCheckedIds.join(",")
          );
          if (currentSpec) {
            this.$emit("change", { ...currentSpec, color: this.color });
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    shooseSpec(value, index) {
      this.$set(this.specCheckedIds, index, Number(value.id));
      if (value.color) {
        this.color = value.color;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.spec-wrap {
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .spec-title {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .spec-color {
    width: 30px;
    height: 30px;
    border: 5px solid #eee;
    margin-right: 10px;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;
    &.selected {
      border-color: $--color-primary;
    }
  }
  .spec-item {
    margin-left: 0 !important;
    margin-right: 8px;
    margin-bottom: 10px;
  }
}
</style>
