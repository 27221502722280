<template>
  <div class="ls-dialog">
    <div class="ls-dialog__trigger" @click="onTrigger">
      <!-- 触发弹窗 -->
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      coustom-class="ls-dialog__content"
      :visible="visible"
      title=""
      width="1000px"
      top="10vh"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="close"
      :close-on-click-modal="true"
    >
      <!-- 弹窗内容 -->

      <div class="dialog-body">
        <div class="material-box">
          <div class="material-left">
            <div class="flex-col">
              <div class="material-left_tab">我的图片</div>
              <div class="material-left_tab">官方素材</div>
            </div>
            <div>
              <el-upload
                drag
                :limit="10"
                action="#"
                :multiple="true"
                :show-file-list="false"
                :http-request="handleBeforeUpload"
                accept=".jpg,.jpeg,.png,.psd"
              >
                <i class="el-icon-upload" style="font-size: 50px"></i>
                <div class="el-upload__text">
                  <div>将图片拖拽到此处上传</div>
                  <div class="xs muted m-t-5">JPG、JPEG、PNG格式</div>
                  <div class="xs muted m-t-5">30M以内</div>
                </div>
                <div class="el-upload__tip" slot="tip">
                  <div class="upload-desc">
                    1、请勿上传涉政、涉黄、侵权等违法图片，情节严重的封号处理
                  </div>
                  <div class="upload-desc">
                    2、上传代表已阅读并同意
                    <span
                      class="primary pointer"
                      @click="LegalNoticeShow = true"
                      >《法律声明》</span
                    >
                  </div>
                </div>
              </el-upload>
            </div>
          </div>

          <div class="material-right">
            <div class="material-right-tool flex justify-between">
              <div class="flex">
                <el-select
                  clearable
                  size="small"
                  v-model="searchForm.cid"
                  @change="getList"
                  placeholder=""
                >
                  <el-option
                    v-for="item in categoryList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
                <el-input
                  style="width: 260px; margin-left: 10px"
                  clearable
                  size="small"
                  v-model="searchForm.name"
                  placeholder="输入图片名称"
                  @keyup.enter.native="getList"
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="getList"
                  ></el-button
                ></el-input>
              </div>
            </div>
            <div class="material-right-list" v-if="list.length > 0">
              <div class="progress" v-if="uploading">
                <div style="width: 80%; margin-left: 10%">
                  <div
                    class="m-b-20"
                    v-for="(item, index) in progressData"
                    :key="index"
                  >
                    <div>{{ item.name }}</div>
                    <div class="flex-1">
                      <el-progress
                        :percentage="parseInt(item.percentage)"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="material-list-item"
                v-for="(item, index) in list"
                :key="index"
                @click="setImgChoose(item)"
              >
                <div class="material-list-item-choose" v-if="item.checked">
                  <span class="iconfont icon-success"></span>
                </div>
                <img :src="getImgStr(item.uri, 110)" alt="" />
                <el-tooltip
                  effect="dark"
                  :content="item.name"
                  placement="bottom-start"
                >
                  <div class="material-list-item-name">{{ item.name }}</div>
                </el-tooltip>
              </div>
            </div>
            <div class="material-right-list flex col-center row-center" v-else>
              没有符合条件的图片
            </div>
            <el-pagination
              :current-page.sync="searchForm.page_no"
              :page-size="searchForm.page_size"
              background
              layout="prev, pager, next, jumper"
              :total="total"
              @current-change="getList"
            ></el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { apiFileListCate, apiFileList } from '@/api/app'

import {
  checkImageBeforeUpload,
  uploadCMYKImage,
  md5Str,
  getCurrentTime,
  filterURLSpecialChars,
  uploadToOss,
  getImageOssUrl
} from '@/utils/design/utils.js'
export default {
  props: {
    type: {
      type: Number,
      default: 1 // 1选择素材2替换图片
    }
  },
  data () {
    return {
      visible: false,
      searchForm: { name: '', cid: '', page_no: 1, page_size: 20 },
      list: [],
      total: 0,
      categoryList: [],
      progressData: [],
      uploading: false,
      uploadSuccessNum: false,
      chooseArr: []
    }
  },
  methods: {
    getImgStr (url, size) {
      return getImageOssUrl(url, size)
    },
    onTrigger () {
      this.open()
    },
    open () {
      this.getList()
      this.getCateList()
      this.visible = true
    },
    close () {
      this.visible = false
    },

    getList () {
      apiFileList(this.searchForm).then((res) => {
        this.list = res.lists
        this.total = res.count
      })
    },
    getCateList () {
      apiFileListCate().then((res) => {
        this.categoryList = res.lists
        this.categoryList.unshift({ id: '', name: '所有图片' })
      })
    },
    editGroupe (value, id) {
      if (!value) {
        this.$message.error('请输入分组名称')
        return
      }
      const params = {
        name: value
      }
      params.cid = id
      apiMaterialCateEdit(params).then((res) => {
        getCateList()
      })
    },
    setImgChoose (value) {
      this.$emit('choose', value)
      this.close()
    },
    async deleteImg () {
      const arr = chooseArr.value.map((img) => img.id)
      await this.$confirm(t('suredelete'))
      apiMaterialDelete({ ids: arr.join(',') }).then((res) => {
        chooseArr.value = []
        getList()
      })
    },
    async handleBeforeUpload (file) {
      const imgmode = await checkImageBeforeUpload(file)
      progressData.value.push({
        percentage: 0,
        name: file.file.name
      })
      uploadFile(file.file, progressData.value.length - 1, imgmode)
    },

    async uploadFile (file, index, imgmode) {
      try {
        uploading.value = true
        let suffix = ''
        if (file.type == 'image/jpeg') {
          suffix = 'jpg'
        } else if (file.type == 'image/png') {
          suffix = 'png'
        }
        file.suffix = suffix
        const file_name = md5Str(file.name)
        const filename = `uploads/${getCurrentTime()}/m5bqcnr2/${filterURLSpecialChars(
          file_name
        )}.${suffix}`

        if (imgmode == 'CMYK') {
          progressData.value[index].percentage = Math.round((0.2 / 1.1) * 100)
          const img = await uploadCMYKImage(file, filename)
          handleSuccess(img, index)
        } else {
          const img = await uploadToOss(file, filename, function (percentage) {
            progressData.value[index].percentage = percentage * 0.9
          })
          handleSuccess(img, index)
        }
      } catch (err) {}
    },
    handleSuccess (value, index) {
      apiPutToOss({
        name: value.name,
        uri: value.url,
        size: (value.size / 1024).toFixed(0),
        format: value.suffix,
        width: value.width,
        height: value.height,
        cid: ''
      }).then((res) => {
        progressData.value[index].percentage = 100
        uploadSuccessNum.value += 1
        progressData.value[index].percentage = 0
        list.value[index].url = value.url
        if (uploadSuccessNum.value == progressData.value.length) {
          progressData.value = []
          uploadSuccessNum.value = 0
          uploading.value = false
          searchForm.page_no = 1
          // this.$message.success(this.$t('uploadsuccess'))
          getList()
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 !important;
}
::v-deep .el-dialog__header {
  padding: 0 !important;
}
::v-deep .el-upload-dragger {
  width: 180px;
}
.material-box {
  display: flex;
  .material-left {
    width: 220px;
    background: #fafafa;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px 0 0 5px;
    border-right: 1px solid #e4e4e4;
    padding: 15px 0 20px 20px;
    .material-left_tab {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      margin-bottom: 10px;
      border-radius: 6px;
      margin-right: 10px;
      cursor: pointer;
    }
  }
  .material-right-category {
    width: 200px;
    .material-category-item {
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: #666;
      .category-item-name {
        width: 150px;
        margin: 0 5px;
      }
      &.on {
        background-color: #f0f4ff;
      }
    }
  }
  .material-right {
    width: 780px;
    margin: 0 10px;
    display: flex;
    flex-direction: column;
    padding: 15px 20px 20px 0;

    .material-right-list {
      margin-top: 10px;
      height: 600px;
      position: relative;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0 !important;
      }
      .progress {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #ffffff, $alpha: 1);
        z-index: 999;
        display: flex;

        justify-content: center;
      }

      .material-list-item {
        width: 136px;
        height: 160px;
        float: left;
        border: 1px solid #dbdbdb;
        margin: 6px;
        border-radius: 5px;
        cursor: pointer;
        position: relative;
        .material-list-item-choose {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color: #000000, $alpha: 0.7);
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          > span {
            font-size: 30px;
            color: #fff;
          }
        }
        img {
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          width: 134px;
          height: 134px;
          margin: 0;
        }
        .material-list-item-name {
          width: 100px;
          margin: 0 5px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
