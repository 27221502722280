
import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { apiModelCategoryList, apiModelList } from "@/api/model";
import { getImageOssUrl } from "@/utils/util";

@Component({
  components: {},
})
export default class DesignProductList extends Vue {
  @Prop({ default: true }) show!: string; //
  @Prop({ default: "1080px" }) width!: string; //

  getImageOssUrl = getImageOssUrl;
  loading = true;
  dialogVisible = false;
  cateList = [];
  defaultProps = {
    label: "zh_cn_name",
    value: "id",
    children: "sons",
  };
  searchForm = {
    cate_id: "",
    page_size: 20,
    page_no: 1,
    zh_cn_title: "",
  };
  list = [];
  total = 0;

  @Watch("show", { immediate: true })
  handleSgow(value: boolean) {
    if (value) {
      this.getCateList();
      this.getProductList();
      this.dialogVisible = true;
    }
  }

  handleCateChange(e: any) {
    this.searchForm.cate_id = e.id;
    this.getProductList();
  }

  getCateList() {
    apiModelCategoryList({}).then((res) => {
      let cateList = res.lists;
      cateList.unshift({ id: "", zh_cn_name: "全部" });
      this.cateList = cateList;
    });
  }
  getProductList() {
    this.loading = true;
    apiModelList(this.searchForm).then((res) => {
      this.list = res.lists;
      this.total = res.count;
      this.loading = false;
    });
  }
}
