<template>
  <div class="ls-dialog">
    <div class="ls-dialog__trigger" @click="onTrigger">
      <!-- 触发弹窗 -->
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      coustom-class="ls-dialog__content"
      :visible="visible"
      title="快捷键"
      width="800"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="close"
      ><div class="keys-box flex">
        <div class="keys-box_item flex-col flex-1 m-r-10">
          <div class="flex row-between">
            <div>移动</div>
            <div class="flex">
              <i class="el-icon-top"></i>
              <i class="el-icon-bottom"></i>
              <i class="el-icon-back"></i>
              <i class="el-icon-right"></i>
            </div>
          </div>
          <div class="flex row-between">
            <div>放大</div>
            <div>+</div>
          </div>
          <div class="flex row-between">
            <div>缩小</div>
            <div>-</div>
          </div>
          <div class="flex row-between">
            <div>删除</div>
            <div>Delete / Backspace</div>
          </div>
        </div>
        <div class="keys-box_item flex-col flex-1 m-r-10">
          <div class="flex row-between">
            <div>保存</div>
            <div>Ctrl + S</div>
          </div>
          <div class="flex row-between">
            <div>复制</div>
            <div>Ctrl + C</div>
          </div>
          <div class="flex row-between">
            <div>裁切</div>
            <div>Ctrl + X</div>
          </div>
          <div class="flex row-between">
            <div>粘贴</div>
            <div>Ctrl + V</div>
          </div>
          <div class="flex row-between">
            <div>撤销</div>
            <div>Ctrl + Z</div>
          </div>
          <div class="flex row-between">
            <div>重做</div>
            <div>Ctrl + Y</div>
          </div>
        </div>
        <div class="keys-box_item flex-col flex-1 m-r-10">
          <div class="flex row-between">
            <div>铺满</div>
            <div>Alt + R</div>
          </div>
          <div class="flex row-between">
            <div>填充</div>
            <div>Alt + T</div>
          </div>
          <div class="flex row-between">
            <div>适应</div>
            <div>Alt + Q</div>
          </div>
          <div class="flex row-between">
            <div>宽度填充</div>
            <div>Alt + W</div>
          </div>
          <div class="flex row-between">
            <div>高度填充</div>
            <div>Alt + H</div>
          </div>
          <div class="flex row-between">
            <div>水平翻转</div>
            <div>Alt + |</div>
          </div>
          <div class="flex row-between">
            <div>垂直翻转</div>
            <div>Alt + /</div>
          </div>
        </div>
      </div></el-dialog
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    onTrigger() {
      this.open();
    },

    close() {
      this.visible = false;
    },

    open() {
      this.visible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.keys-box {
  align-items: flex-start;
  .keys-box_item {
    padding-right: 20px;
    border-right: 1px solid #eee;
    font-size: 12px;
    &:last-child {
      border-right: none;
    }
    > div {
      margin-bottom: 10px;
    }
  }
}
</style>
