<template>
  <el-popover
    ref="colorpopover"
    placement="top"
    v-model="visible"
    width="230px"
    trigger="manual"
    class="popover-input"
  >
    <div class="color-picker-wrap">
      <Chrome v-model="color" />
      <div class="common-use">
        <div>常用颜色</div>
        <div class="flex flex-wrap">
          <div
            class="common-use-item"
            v-for="(item, index) in commonColor"
            @click="chooseColor(item)"
            :key="index"
            :style="{ 'background-color': item }"
          ></div>
        </div>
      </div>
      <div class="history-use">
        <div>最近使用</div>
        <div class="flex col-top">
          <div class="pipette" @click="pipetteColor">
            <svg
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
            >
              <path
                d="M868.304 147.536a164.68 164.68 0 0 0-117.264-48.568c-44.272 0-85.872 17.224-117.128 48.504L499.2 282.208l-55.76-55.76a30.088 30.088 0 0 0-42.568 0 30.088 30.088 0 0 0 0 42.568l76.808 76.808c0.08 0.08 0.136 0.176 0.216 0.264l7.008 7-291.904 291.968a124.76 124.76 0 0 0-35.208 108.68l-45.96 45.984a72.904 72.904 0 0 0-21.52 51.888 72.776 72.776 0 0 0 21.488 51.888 72.944 72.944 0 0 0 51.952 21.544h0.024a72.696 72.696 0 0 0 51.832-21.496l45.736-45.672a124.72 124.72 0 0 0 20.76 1.784c32.72 0 64.56-12.84 88.608-36.864l291.936-291.952 7.016 7.016c0.112 0.112 0.24 0.184 0.36 0.296l76.72 76.712a30.048 30.048 0 0 0 42.568 0 30.088 30.088 0 0 0 0-42.568l-55.76-55.752 134.752-134.72c64.592-64.56 64.592-169.656 0-234.288z m-540.16 632.664c-16.744 16.712-40.736 22.84-63.376 16.76a30.048 30.048 0 0 0-30.616 3.512 63.552 63.552 0 0 0-3.08 2.504l-58.064 58.032a13.048 13.048 0 0 1-9.232 3.824h-0.024a13.224 13.224 0 0 1-9.344-22.544l56.08-56.104c0.392-0.344 4.288-4.608 5.2-5.944 0.408-0.576 2.144-3.568 2.576-4.512 0.504-1.064 1.624-4.32 1.768-4.864 0.416-1.552 0.904-5 0.92-5.16a30.088 30.088 0 0 0-1.96-14.08c-6.456-22.864-0.304-47.136 16.568-64l291.928-291.96 92.6 92.6-291.944 291.936z m497.592-440.96L690.976 473.968l-149.2-149.184 134.712-134.736c39.832-39.824 109.36-39.792 149.248 0.056 41.096 41.144 41.096 108.04 0 149.136z"
                fill="#838384"
              ></path>
            </svg>
          </div>
          <div class="history-use-content">
            <div class="history-use-item">
              <img
                @click="chooseColor('')"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAABI5JREFUeF7t3V1S2zAUQGEpb0xgD2EjidkJWQmwErITnGyk7hoK05c26gTCFBLHv1fylXT6Wo8s6zsxshkm1vAv6xWwWV89F28IIPMICIAAMl+ByC//d1Es/hizmO33Kzeb/XTGVDdlWXa9LO4AXVdK4XFvy+WDsfaxZmrVzNq7q7Ks2qZNAG0rpPD/D5/6v849W2OKhulVxrnNfLd7aroEAlAI3DSlX0VRzJx76Txt5x6bIiCAzis5/YG98Y9Tdtaur8tyU3cFBDC9a6cZDMU/Dn5xT0AAnZZ/2oNG4r9P3hlTXm+3d6dXQgDT2raeXQL/8y4w325vCaB1yfUcIIj/flF7a+9O3xFwB9Dj/W0m0viHwWfW3p6+GyAAhQH4wGcPoBC6bko+8I/n2cy32zV7AMUheMSv6jaAh6XgR4CSIDzi127+Pi+bABQE4BO/6S0gd4DM8Qlg4gCm/OTzIwD89xVgDzBBCBo++dwBJoA/nFITPneAwBFowyeAgAFoxCeAQAFoxSeAAAFoxicAzwFoxycAjwHEgE8AngKIBZ8APAQQEz4BCAcQGz4BCAYQIz4BCAUQKz4BCAQQMz4BjAwgdnwCGBFACvgEMDCAVPAJYEAAKeETQM8AUsMngB4BpIhPAB0DSBWfADoEkDI+AbQEkDo+ATQEkAM+AVwIIBd8AqgJICd8AjgJIDd8AvgSQI74BHAMIFd8AlD4t3odXk2IHpL1Xwfn/Mn/rCjbAMD/SCDLAMD//1MkuwDA/76FyCoA8M/3j9kEAH79w0MWAYB/+ckx+QDAb35tkHQA4Le/M0o2APDb8ZN9DwB+N/wkAwC/O35yAYDfDz+pAMDvj59MAOAPw08iAPCH40cfAPjj8KMOAPzx+NEGAL4MfpQBgC+HH10A4MviRxUA+PL40QQAvh/8KAIA3x+++gDA94uvOgDw/eOrDQD8MPgqAwA/HL66AMAPi68qAPDD46sJAPxp8FUEAP50+JMHAP60+JMGAP70+JMFAL4O/EkCAF8PfvAAwNeFHzQA8PXhBwsAfJ34QQIAXy++9wDA143vNQDw9eN7CwD8OPC9BAB+PPjiAYAfF75oAODHhy8WAPhx4osEAH68+KMDAD9u/FEBgB8//uAAwE8Df1AA4KeD3zsA8NPC7xUA+Onhdw4A/DTxOwUAfrr4rQGAnzZ+YwC/i2Kxd+6HjyVw1q6vy3LjY2zG7LcCF78w4nW1erHGFP2Gaz8a/PY1CnlEbQBvy+WDsfZReiLgS6/o+PHqA1it3Pihv48AvvSKyox3FsBrUdxb555lhv8YBXzJ1ZQd6yyAt9XqgH8vdRrwpVbSzzhnAUg++oHvB01y1LMApB7/wJdk8jdW7SZw7CMg+P7ApEeuDWDMXQB8aSK/411+ETTgaQB8v1g+Rm/86tg+L4TA98Hjf8zW7w7u8FRQ7a1d35Rl6X+6nEF6BVoDOJzwuCd4cMYsvvx+oDLObea73ZP0pBgv3Ap0CuDrdA4xXJVlFW6KnMnnCvQOwOdkGDv8ChBA+DVXdUYCUMURfjIEEH7NVZ3xH5t4ua4SaXmdAAAAAElFTkSuQmCC"
                alt=""
              />
            </div>
            <div
              class="history-use-item"
              v-for="(item, index) in historycolors"
              :key="index"
              @click="chooseColor(item)"
              :style="{ 'background-color': item }"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="inline" type="text" slot="reference" @click="handleOpen">
      <slot></slot>
    </div>
  </el-popover>
</template>
<script>
import { Chrome } from 'vue-color'
import Cache from '@/utils/cache'

export default {
  components: {
    Chrome
  },
  props: {},
  data () {
    return {
      visible: false,
      color: { hex: '' },
      commonColor: [
        '#E02020',
        '#FA6401',
        '#F7B500',
        '#6DD400',
        '#44D7B6',
        '#32C5FF',
        '#0091FF',
        '#6236FF',
        '#B729E0',
        '#6D7278',
        '#E5E5E5',
        '#BFBFBF',
        '#7F7F7F',
        '#262626',
        '#000000',
        '#FFFFFF'
      ],
      historycolors: []
    }
  },
  watch: {
    color: {
      handler (value) {
        this.chooseColor(value.hex)
      }
    }
  },
  methods: {
    handleOpen () {
      this.visible = !this.visible
    },
    close () {
      this.visible = false
    },
    chooseColor (value) {
      this.$emit('change', value)
      if (this.historycolors.indexOf(value) > -1) {
        return
      }
      if (this.historycolors.length == 10 && value != '') {
        this.historycolors.pop()
      }
      this.historycolors.unshift(value)
      Cache.set('historycolors', this.historycolors)
    },
    pipetteColor () {
      if ('EyeDropper' in window) {
        const eveDropper = new EyeDropper()
        eveDropper.open().then((res) => {
          console.log(res)
          this.chooseColor(res.sRGBHex)
        })
      }
    },

    handleClickOutside (event) {
      // 检查点击的区域是否在弹出框之外
      if (
        this.$refs.colorpopover &&
        !this.$refs.colorpopover.$el.contains(event.target)
      ) {
        this.visible = false
      }
    }
  },
  mounted () {
    //
    document.addEventListener('click', this.handleClickOutside)
    let historycolors = Cache.get('historycolors') || ['#000000', '#ffffff']
    if (historycolors.length > 10) {
      historycolors = historycolors.splice(0, 10)
    }
    this.historycolors = historycolors
  }
}
</script>
<style lang="scss" scoped>
.color-picker-wrap {
  background-color: #fff;
  width: 230px;
  height: 410px;
  .vc-chrome {
    box-shadow: none !important;
  }
  .common-use {
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    .common-use-item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 5px 6px 5px 0;
      cursor: pointer;
      border: 1px solid #e5e5e5;
      &:hover {
        border-color: $--color-primary;
      }
    }
  }
  .history-use {
    padding: 5px 8px;
    display: flex;
    flex-direction: column;
    .pipette {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #e5e5e5;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 5px 10px 0 0;
      cursor: pointer;
    }
    .history-use-content {
      display: flex;
      flex-wrap: wrap;
      width: 180px;
      .history-use-item {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin: 5px 6px 5px 0;
        cursor: pointer;
        border: 1px solid #e5e5e5;
        &:hover {
          border-color: $--color-primary;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>
