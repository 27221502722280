<template>
  <div class="menu">
    <div
      class="menu-item flex flex-col row-center col-center"
      :id="`menu-${index}`"
      v-for="(item, index) in menus"
      :key="index"
      :class="menuIndex == index ? 'active bg-white' : ''"
      @click="changeMenuIndex(index)"
    >
      <span :class="`iconfont icon-${item.icon}`"></span>
      <span class="text-[12px]">{{ item.text }}</span>
    </div>
    <div class="version">v{{ version }}</div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      menus: [
        { icon: 'goods', text: '商品' },
        { icon: 'gallery', text: '素材' },
        // { icon: "upload", text: "上传" },
        { icon: 'text', text: '文本' }
      ],
      menuIndex: 1,
      version: '1.0.0'
    }
  },
  methods: {
    changeMenuIndex (index) {
      this.menuIndex = index
      this.$emit('menuChange', index)
    }
  }
}
</script>
<style lang="scss" scoped></style>
