<template>
  <div>
    <div v-for="(item, index) in attrInfo" :key="index">
      <el-divider content-position="left">{{ item.name }}</el-divider>
      <div class="flex flex-wrap" v-if="item.type == 1">
        <div class="flex">
          <div>选择颜色：</div>
          <color-picker @change="setColor(index, $event)">
            <div
              style="
                width: 30px;
                height: 30px;
                border: 1px solid #e5e5e5;
                cursor: pointer;
              "
              class="flex col-center row-center"
            >
              <div
                style="width: 85%; height: 85%"
                :style="{ 'background-color': item.value }"
              ></div>
            </div>
          </color-picker>
        </div>
      </div>
      <div class="flex flex-wrap" v-else-if="item.type == 2">
        <div
          class="attr-color empty"
          @click="setColor(index, 'transparent')"
        ></div>
        <div
          class="attr-color"
          @click="setColor(index, color.color)"
          v-for="(color, colorIndex) in item.attr_list"
          :key="colorIndex"
          :style="{ background: color.color }"
        ></div>
      </div>
      <div class="flex flex-wrap" v-else-if="item.type == 3">
        <el-button size="mini" round>无</el-button>
        <el-button
          round
          size="mini"
          v-for="(material, materialIndex) in item.attr_list"
          :key="materialIndex"
          @click="setMaterial(index, material)"
          >{{ material.zh_cn_name }}</el-button
        >
      </div>
      <div class="flex flex-wrap" v-else-if="item.type == 4">
        <el-button size="mini" round>无</el-button>
        <el-button
          round
          size="mini"
          v-for="(citem, cindex) in item.attr_list"
          :key="cindex"
          @click="setEffect(index, citem)"
          >{{ citem.zh_cn_name }}</el-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import ColorPicker from "../color-picker.vue";

export default {
  props: {
    value: {
      type: Object,
    },
  },
  components: {
    ColorPicker,
  },
  watch: {
    value: {
      handler(val) {
        if (val.attrs) {
          this.attrInfo = val.attrs;
          for (const attr of this.attrInfo) {
            attr.value = "";
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      attrInfo: [],
    };
  },
  methods: {
    setColor(index, color) {
      this.$emit("change", {
        type: 1,
        parts: this.attrInfo[index].parts,
        value: color,
      });
      this.$nextTick(() => {
        this.$set(this.attrInfo[index], "value", color);
      });
    },
    setMaterial(index, material) {
      this.$emit("change", {
        type: 3,
        parts: this.attrInfo[index].parts,
        value: material,
      });
    },

    setEffect(index, effect) {
      this.$emit("change", {
        type: 4,
        parts: [],
        value: effect,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.attr-color {
  width: 30px;
  height: 30px;
  border: 5px solid #eee;
  margin-right: 10px;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  &.empty {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAAAXNSR0IArs4c6QAABI5JREFUeF7t3V1S2zAUQGEpb0xgD2EjidkJWQmwErITnGyk7hoK05c26gTCFBLHv1fylXT6Wo8s6zsxshkm1vAv6xWwWV89F28IIPMICIAAMl+ByC//d1Es/hizmO33Kzeb/XTGVDdlWXa9LO4AXVdK4XFvy+WDsfaxZmrVzNq7q7Ks2qZNAG0rpPD/D5/6v849W2OKhulVxrnNfLd7aroEAlAI3DSlX0VRzJx76Txt5x6bIiCAzis5/YG98Y9Tdtaur8tyU3cFBDC9a6cZDMU/Dn5xT0AAnZZ/2oNG4r9P3hlTXm+3d6dXQgDT2raeXQL/8y4w325vCaB1yfUcIIj/flF7a+9O3xFwB9Dj/W0m0viHwWfW3p6+GyAAhQH4wGcPoBC6bko+8I/n2cy32zV7AMUheMSv6jaAh6XgR4CSIDzi127+Pi+bABQE4BO/6S0gd4DM8Qlg4gCm/OTzIwD89xVgDzBBCBo++dwBJoA/nFITPneAwBFowyeAgAFoxCeAQAFoxSeAAAFoxicAzwFoxycAjwHEgE8AngKIBZ8APAQQEz4BCAcQGz4BCAYQIz4BCAUQKz4BCAQQMz4BjAwgdnwCGBFACvgEMDCAVPAJYEAAKeETQM8AUsMngB4BpIhPAB0DSBWfADoEkDI+AbQEkDo+ATQEkAM+AVwIIBd8AqgJICd8AjgJIDd8AvgSQI74BHAMIFd8AlD4t3odXk2IHpL1Xwfn/Mn/rCjbAMD/SCDLAMD//1MkuwDA/76FyCoA8M/3j9kEAH79w0MWAYB/+ckx+QDAb35tkHQA4Le/M0o2APDb8ZN9DwB+N/wkAwC/O35yAYDfDz+pAMDvj59MAOAPw08iAPCH40cfAPjj8KMOAPzx+NEGAL4MfpQBgC+HH10A4MviRxUA+PL40QQAvh/8KAIA3x+++gDA94uvOgDw/eOrDQD8MPgqAwA/HL66AMAPi68qAPDD46sJAPxp8FUEAP50+JMHAP60+JMGAP70+JMFAL4O/EkCAF8PfvAAwNeFHzQA8PXhBwsAfJ34QQIAXy++9wDA143vNQDw9eN7CwD8OPC9BAB+PPjiAYAfF75oAODHhy8WAPhx4osEAH68+KMDAD9u/FEBgB8//uAAwE8Df1AA4KeD3zsA8NPC7xUA+Onhdw4A/DTxOwUAfrr4rQGAnzZ+YwC/i2Kxd+6HjyVw1q6vy3LjY2zG7LcCF78w4nW1erHGFP2Gaz8a/PY1CnlEbQBvy+WDsfZReiLgS6/o+PHqA1it3Pihv48AvvSKyox3FsBrUdxb555lhv8YBXzJ1ZQd6yyAt9XqgH8vdRrwpVbSzzhnAUg++oHvB01y1LMApB7/wJdk8jdW7SZw7CMg+P7ApEeuDWDMXQB8aSK/411+ETTgaQB8v1g+Rm/86tg+L4TA98Hjf8zW7w7u8FRQ7a1d35Rl6X+6nEF6BVoDOJzwuCd4cMYsvvx+oDLObea73ZP0pBgv3Ap0CuDrdA4xXJVlFW6KnMnnCvQOwOdkGDv8ChBA+DVXdUYCUMURfjIEEH7NVZ3xH5t4ua4SaXmdAAAAAElFTkSuQmCC);
    background-size: cover;
    background-repeat: no-repeat;
  }
  &:hover {
    border-color: $--color-primary;
  }
  &.selected {
    border-color: $--color-primary;
  }
}
</style>
