<template>
  <div class="ls-dialog">
    <div class="ls-dialog__trigger" @click="onTrigger">
      <!-- 触发弹窗 -->
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      coustom-class="ls-dialog__content"
      :visible="visible.val"
      title="裁剪"
      width="700px"
      top="10vh"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="close"
      :close-on-click-modal="true"
    >
      <!-- 弹窗内容 -->

      <div class="dialog-body">
        <div
          style="height: 500px"
          element-loading-background="rgba(255, 255, 255, 0.7)"
          element-loading-text=""
          v-loading="croping"
        >
          <vueCropper
            ref="cropper"
            :img="cropImg.img"
            :outputSize="cropImg.size"
            :outputType="cropImg.outputType"
            :info="cropImg.info"
            :canScale="cropImg.canScale"
            :autoCrop="cropImg.autoCrop"
            :maxImgSize="cropImg.maxImgSize"
            :full="cropImg.full"
            :enlarge="cropImg.enlarge"
          ></vueCropper>
        </div>
      </div>

      <!-- 底部弹窗页脚 -->
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="close">取消</el-button>
        <el-button size="small" type="primary" @click="confirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { VueCropper } from 'vue-cropper'

export default {
  props: {
    image: {
      type: String
    }
  },
  components: {
    VueCropper
  },
  data () {
    return {
      visible: { val: false },
      croping: false,
      cropImg: {
        img: '',
        info: true,
        size: 1,
        outputType: 'jpeg',
        canScale: true,
        autoCrop: true,
        // 只有自动截图开启 宽度高度才生效
        autoCropWidth: 300,
        autoCropHeight: 300,
        fixed: false,
        // 真实的输出宽高
        infoTrue: true,
        fixedNumber: [4, 3],
        full: true,
        maxImgSize: 'max',
        enlarge: 5
      }
    }
  },
  watch: {
    image: {
      handler (value) {
        const imgEl = document.createElement('img')
        imgEl.src = value
        imgEl.setAttribute('crossOrigin', 'Anonymous')
        imgEl.onload = () => {
          this.cropImg.img = value
          if (value.indexOf('.png') > -1) {
            this.cropImg.outputType = 'png'
          } else {
            this.cropImg.outputType = 'jpeg'
          }
          this.cropImg.enlarge = imgEl.height / 500
          imgEl.remove()
        }
      }
    }
  },
  methods: {
    handleEvent (type) {
      this.$emit(type)
      if (!this.async || type === 'cancel') this.close()
    },
    onTrigger () {
      if (this.disabled) return
      this.open()
    },
    confirm () {
      this.$refs.cropper.getCropData((data) => {
        console.log(data)
      })
    },
    close () {
      this.visible.val = false
      this.$emit('close')
    },
    open () {
      this.visible.val = true
      this.$emit('open')
    }
  }
}
</script>

<style scoped lang="scss">
.dialog-body {
  white-space: pre-line;
}
.title-icon {
  font-size: 24px;
  color: $--color-warning;
  margin-right: 5px;
}
</style>
