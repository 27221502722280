<template>
  <div class="upload-content">
    <el-upload
      drag
      :limit="10"
      :action="uploadImgServer"
      :headers="uploadImgHeaders"
      :data="{ cid: 3 }"
      :multiple="true"
      :show-file-list="false"
      accept=".jpg,.jpeg,.png,.psd"
      :on-success="handleSuccess"
    >
      <!-- :http-request="handleBeforeUpload" -->
      <i class="el-icon-upload" style="font-size: 50px"></i>
      <div class="el-upload__text">
        <div>将文件拖到此处，或点击上传</div>
        <div class="xs muted m-t-5">JPG、JPEG、PNG格式，30M以内</div>
      </div>
      <div class="el-upload__tip" slot="tip">
        <div class="upload-desc">
          1、请勿上传涉政、涉黄、侵权等违法图片，情节严重的封号处理
        </div>
        <div class="upload-desc m-t-5">
          2、上传代表已阅读并同意
          <span class="primary pointer" @click="LegalNoticeShow = true"
            >《法律声明》</span
          >
        </div>
      </div>
    </el-upload>

    <div class="flex m-t-10 m-b-5 col-center">
      <el-input
        size="small"
        placeholder="请输入搜索关键字"
        v-model="searchForm.name"
        clearable
        @clear="resetData"
        @keyup.enter.native="resetData"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="resetData"
        ></el-button
      ></el-input>
      <el-popover placement="right" title="" class="m-l-5" trigger="click">
        <div>
          <el-cascader-panel
            :options="categoryList"
            :props="{ label: 'name' }"
          ></el-cascader-panel>
        </div>
        <el-button slot="reference" size="mini"
          ><span class="iconfont icon-shaixuan"></span
        ></el-button>
      </el-popover>
    </div>
    <div
      class="material-popup"
      v-if="materialPopupShow"
      :style="{ top: materialDetailTop + 'px' }"
    >
      <img
        :src="materialDetail.uri + '?x-oss-process=image/resize,w_150,h_150'"
      />
      <div class="material-name m-t-10">
        {{ materialDetail.name || materialDetail.title }}
      </div>
      <div class="flex row-between">
        <div
          class="muted f-12"
          v-if="materialDetail.width && materialDetail.height"
        >
          像素: {{ materialDetail.width }}px * {{ materialDetail.height }}px
        </div>
        <div class="muted f-12">{{ getSize(materialDetail.size) }}</div>
      </div>
    </div>
    <div
      v-infinite-scroll="loadMore"
      class="material-wrap"
      v-if="materialList.length > 0"
    >
      <div class="material-content">
        <div
          class="material-item"
          v-for="(item, index) in materialList"
          :key="index"
          @mouseover="showDetails(index, item)"
          @mouseout="hideDetails"
        >
          <div
            class="progress"
            v-if="progressData[index] && progressData[index]['percentage'] > 0"
          >
            <el-progress
              type="circle"
              color="var(--el-color-primary)"
              :percentage="progressData[index]['percentage']"
            />
          </div>
          <img :src="getImgStr(item.uri, 88)" @click="setImage(item)" alt="" />
        </div>
      </div>
    </div>
    <div class="material-wrap flex col-center row-center" v-else>
      <div class="muted nr">您暂未上传图片~</div>
    </div>
    <el-dialog title="法律声明" v-model="LegalNoticeShow" width="800px">
      <div></div>
    </el-dialog>
  </div>
</template>
<script>
import { apiFileListCate, apiFileList } from "@/api/app";

import {
  // checkImageBeforeUpload,
  // uploadCMYKImage,
  // md5Str,
  // getCurrentTime,
  // filterURLSpecialChars,
  // uploadToOss,
  preUpload,
  getImageOssUrl,
} from "@/utils/design/utils";
import config from "@/config";

export default {
  components: {},
  data() {
    return {
      LegalNoticeShow: false,
      categoryList: [],
      searchForm: { name: "", cid: "", page_no: 1, page_size: 30, cid: 3 },
      materialList: [],
      loadcompleted: false,
      uploadSuccessNum: 0,
      progressData: [],
      materialPopupShow: false,
      materialDetailTop: 0,
      materialDetail: {},
      uploadImgHeaders: {
        token: this.$store.getters.token,
        version: config.version,
      },
      uploadImgServer: `${config.baseURL}/platformapi/upload/image`,
    };
  },
  mounted() {
    this.getMaterialList();
    this.getCateList();
  },
  methods: {
    getImgStr(url, size) {
      return getImageOssUrl(url, size);
    },

    handleSuccess() {
      console.log(111);
      this.resetData();
    },
    setImage(value) {
      fetch(`${value.uri}?x-oss-process=image/info`)
        .then((response) => response.json())
        .then((res) => {
          value.width = Number(res.ImageWidth.value);
          value.height = Number(res.ImageHeight.value);
          this.$emit("confirm", value);
        });
    },
    getCateList() {
      apiFileListCate().then((res) => {
        this.categoryList = res.lists;
        this.categoryList.unshift({ id: "", name: "所有图片" });
      });
    },
    getMaterialList() {
      if (this.loadcompleted) {
        return;
      }
      apiFileList(this.searchForm).then((res) => {
        this.materialList = this.materialList.concat(res.lists);
        if (this.materialList.length == res.count) {
          this.loadcompleted = true;
        }
      });
    },
    resetData() {
      this.loadcompleted = false;
      this.searchForm.page_no = 1;
      this.materialList = [];
      this.getMaterialList();
    },
    loadMore() {
      this.searchForm.page_no += 1;
      this.getMaterialList();
    },
    showDetails(index, item) {
      const element = document.querySelector(
        `.material-item:nth-child(${index + 1})`
      );
      if (element) {
        const rect = element.getBoundingClientRect();
        this.materialDetail = item;
        this.materialDetailTop = rect.top - 90;
        this.materialPopupShow = true;
        // 在这里使用 rect
      } else {
      }
    },

    hideDetails() {
      this.materialPopupShow = false;
    },
    getSize(size) {
      if (size > 1024) {
        return (size / 1024).toFixed(2) + "MB";
      } else if (size < 1024 && size > 0) {
        return size + "KB";
      } else {
      }
    },
    async handleBeforeUpload(file) {
      // preUpload(file.file);
      // console.log(file.file)
      // apiFileUpload({ cid: 3, file: file }).then((res) => {
      //   console.log(res);
      // });
    },

    async uploadFile(file, index, imgmode) {
      // try {
      //   let suffix = "";
      //   if (file.type == "image/jpeg") {
      //     suffix = "jpg";
      //   } else if (file.type == "image/png") {
      //     suffix = "png";
      //   }
      //   file.suffix = suffix;
      //   let file_name = md5Str(file.name);
      //   let filename = `uploads/${getCurrentTime()}/m5bqcnr2/${filterURLSpecialChars(
      //     file_name
      //   )}.${suffix}`;
      //   if (imgmode == "CMYK") {
      //     this.progressData[index].percentage = Math.round((0.2 / 1.1) * 100);
      //     let img = await uploadCMYKImage(file, filename);
      //     this.handleSuccess(img, index);
      //   } else {
      //     let img = await this.uploadToOss(file, filename, function (percentage) {
      //       this.progressData[index].percentage = percentage * 0.9;
      //     });
      //     this.handleSuccess(img, index);
      //   }
      // } catch (err) {}
    },

    // handleSuccess(value, index) {
    // this.apiPutToOss({
    //   name: value.name,
    //   uri: value.url,
    //   size: (value.size / 1024).toFixed(0),
    //   format: value.suffix,
    //   width: value.width,
    //   height: value.height,
    //   cid: "",
    // }).then((res) => {
    //   this.progressData[index].percentage = 100;
    //   this.uploadSuccessNum += 1;
    //   this.progressData[index]["percentage"] = 0;
    //   this.materialList[index]["url"] = value.url;
    //   if (uploadSuccessNum.value == this.progressData.length) {
    //     this.progressData = [];
    //     this.uploadSuccessNum = 0;
    //     // this.$message.success(this.$t('uploadsuccess'))
    //     this.resetData();
    //   }
    // });
    // },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-cascader-panel {
  border-color: transparent;
}
::v-deep .el-upload-dragger {
  width: 300px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .el-icon-upload {
    margin: 0 auto 10px auto;
  }
}

.material-popup {
  position: fixed;
  left: 400px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 15px;
  background-color: #fff;
  z-index: 999;
  border: 1px solid #eee;

  .el-image {
    width: 200px;
    height: 200px;
    margin-top: 5px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 40%;
    left: -5px;
    width: 0;
    height: 0;
    // transform: translateY(-40%);
    border-top: 10px solid transparent;
    border-right: 10px solid #fff;
    transform: rotate(135deg);
  }

  .material-name {
    width: 196px;
    height: 36px;
    line-height: 36px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
  }
}

.material-wrap {
  padding: 0;
  overflow: auto;
  scrollbar-width: none;
  height: calc(100vh - 410px);

  .material-content {
    display: flex;
    flex-wrap: wrap;
    .material-item {
      width: 90px;
      height: 90px;
      margin: 5px;
      border-radius: 5px;
      cursor: pointer;
      position: relative;
      border: 1px solid #dbdbdb;
      .progress {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 999;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border-radius: 5px;
      }
      img {
        width: 88px;
        height: 88px;
      }
    }
  }
}
</style>
