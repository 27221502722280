<template>
  <div class="ls-dialog">
    <div class="ls-dialog__trigger" @click="onTrigger">
      <!-- 触发弹窗 -->
      <slot name="trigger"></slot>
    </div>
    <el-dialog
      coustom-class="ls-dialog__content"
      :visible="visible.val"
      title="预览"
      width="800px"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="close"
      :close-on-click-modal="true"
    >
      <div style="height: 620px; overflow: hidden">
        <canvas
          ref="effectCanvas"
          id="effectCanvas"
          style="position: absolute; left: 0; z-index: 1"
          width="600"
          height="600"
        ></canvas>
        <div
          class="bigImgBox"
          style="
            z-index: 2;
            width: 750px;
            height: 620px;
            position: absolute;
            background-color: #fff;
          "
        >
          <div class="bigImgWrap clearFix">
            <div class="leftBig">
              <img :src="images[current]['preview_img']" alt="" />
            </div>
            <div class="rightBig">
              <div class="btnWrap btnTop" @click="scrollUp"></div>
              <div class="btnWrap btnDown" @click="scrollDown"></div>
              <div class="slide-hidden">
                <div class="slide-sroll" :style="containerStyle">
                  <div
                    class="slide-item"
                    :class="index == current ? 'on' : ''"
                    v-for="(item, index) in images"
                    :key="index"
                    @click="current = index"
                    v-loading="!item.preview_loaded"
                    element-loading-background="rgba(255, 255, 255, 1)"
                  >
                    <img
                      :src="item['preview_img']"
                      alt=""
                      style="z-index: 0; position: absolute"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    effectCurrent: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visible: { val: false },
      current: 0,
      scrollPage: 1,
      scrollStep: 4,
      scrollPosition: 0,
      images: [{ preview_img: "", preview_loaded: false }],
    };
  },
  watch: {
    effectCurrent: {
      handler(val) {
        this.scrollPosition = 0;
        this.current = val;
        this.scrollPage = Math.ceil((val + 1) / this.scrollStep);
        this.scrollPosition -= 436 * (this.scrollPage - 1);
      },
    },
  },
  computed: {
    containerStyle() {
      return {
        transform: `translate3d(0, ${this.scrollPosition}px, 0)`,
      };
    },
  },
  methods: {
    scrollDown() {
      if (this.scrollPage * this.scrollStep < this.images.length) {
        this.scrollPosition -= 436;
        this.scrollPage += 1;
      } else {
        // 如果已经在顶部，则滚动到底部
        this.scrollPosition = 0;
        this.scrollPage = 1;
      }
      window.requestAnimationFrame(() => {
        this.$forceUpdate(); // 强制Vue更新DOM
      });
    },

    scrollUp() {
      if (this.scrollPage > 1) {
        this.scrollPosition += 436;
        this.scrollPage -= 1;
      } else {
        // 如果已经在顶部，则滚动到底部
        this.scrollPage = Number(
          (this.images.length / this.scrollStep).toFixed(0)
        );
        this.scrollPosition = -436 * (this.scrollPage - 1);
      }
      window.requestAnimationFrame(() => {
        this.$forceUpdate(); // 强制Vue更新DOM
      });
    },

    updateScrollPosition(delta) {
      const newPos = this.scrollPosition + delta;
      this.scrollPosition = newPos;
      // 使用requestAnimationFrame来平滑滚动
      window.requestAnimationFrame(() => {
        this.$forceUpdate(); // 强制Vue更新DOM
      });
    },

    onTrigger() {
      this.open();
    },

    close() {
      this.visible.val = false;
      this.$emit("close");
    },
    open() {
      this.visible.val = true;
      this.images = this.value;
      this.$nextTick(async () => {
        for (let item of this.images) {
          if (item.is_diy || (!item.is_diy && item.preview_img == "")) {
            let images = JSON.parse(JSON.stringify(item.currentImgs));
            if (item.bigimg) {
              images.splice(item.currentImgIndex, 0, item.bigimg);
            }
            item.preview_img = await this.createEffectCanvas(images);
            item.preview_loaded = true;
          }
        }
      });
    },
    createEffectCanvas(images) {
      return new Promise((resolve, reject) => {
        const canvas = document.getElementById("effectCanvas");
        const ctx = canvas.getContext("2d");
        canvas.height = 600;
        const imglen = images.length;
        (function f(n) {
          if (n < imglen) {
            const image = new Image();
            if (images[n] == "") {
              return f(n + 1);
            }
            image.src = images[n];
            image.setAttribute("crossOrigin", "anonymous");
            image.onload = () => {
              ctx.drawImage(image, 0, 0, 600, 600);
              f(n + 1);
            };
          } else {
            resolve(canvas.toDataURL());
          }
        })(0);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.leftBig {
  overflow: hidden;
  float: left;
  width: 600px;
  height: 600px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  img {
    width: 598px;
    height: 598px;
    border-radius: 12px;
    vertical-align: middle;
    border-style: none;
  }
}
.rightBig {
  float: left;
  position: relative;
  padding: 30px 0;
  margin-left: 20px;
  width: 100px;
  height: 500px;
  .btnWrap {
    position: absolute;
    left: 0;
    right: 0;
    height: 30px;
    border-radius: 15px;
    cursor: pointer;
    z-index: 999;
  }
  .btnTop {
    top: 0;
    font-size: 10px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAAXNSR0IArs4c6QAAASZJREFUOE+N07FKhlAUB/D/kbCG3sAxe4LWBsceIIiWEOpeSBBqqi23goaiJj3iE3zR2BDNPUH0KoZyT/ihobdP/Rw9f3/8D/dKmHmCINjwff+biD6Z+WQuT1OBOI43y7L8IqKdJiciL8x8OPXNJKiUeiKiuANERACcMXMxho6CWusHABeNQTSIGRFRY+hKsMN6zZ6JaBfAQfvOGGOO8jxf2E3/gTYG4DHLssswDLdc133tobUx5thGB+AY1rVYB/0DlVJ3RHTVW2HZzF6pRd8B7LezQdMlqLVOANzMYd08iqLtuq7fVqFkYyJSMPPp3AUeQxvwGsBte3ELz/NUkiRmDmzmFvrTnHy38rmI7Hmep9fF+utXVbVwHOc+TdOPyT9lnZZ25hdYgKEJu+rJkQAAAABJRU5ErkJggg==")
      50% #f2f3f7 no-repeat;
  }
  .btnDown {
    bottom: 0;
    font-size: 10px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAAAXNSR0IArs4c6QAAATlJREFUOE+N0j9KxUAQBvBvHhgtvMGCnRdIWg/gAQSxkYBmwUBAK+1Mp2ChaJXd8E7wxNJCrL2BhSeJJOzIhs0jifnztsvu7I9vskMAIKW8YOZACCHTNDV2b9MVx/FuWZarxWLxkGXZJ0kpbwDcWYCZl0KIaFPUYlVVvQM4APBrjDm2YArgtklkUa312VzCHmbLK2PMCbmWOyiAJ6XU1Rg6huV5vqpBu6Iouiei6xYyiIZhuON53odrc53MYvZjDbqkjwAux1CHvQE4dDV1mw32D5xCBzBjH6GNDYJDKDO/ENF+K5lh5khrvez/507L7UMpZad9ZgZRXT6KjSZsPdQzESWtkWIA50PJmprRhLYgSZLtoih+iGjPDf6r1vpoakYnQXsxCIIt3/e/iehLKXU6N/Cz4BzQP/8DizyhCa5+BqoAAAAASUVORK5CYII=")
      50% #f2f3f7 no-repeat;
  }
  .slide-hidden {
    overflow: hidden;
    height: 436px;
    cursor: pointer;
    .slide-sroll {
      transition: transform 0.5s ease 0s, -webkit-transform 0.5s ease 0s;
      .slide-item {
        box-sizing: border-box;
        overflow: hidden;
        width: 100px;
        height: 100px;
        border: 1px solid #d9d9d9;
        border-radius: 12px;
        margin-top: 8px;
        &.on {
          border: 1px solid $--color-primary !important;
        }
        img {
          width: 98px;
          border-radius: 12px;
          vertical-align: middle;
          border-style: none;
        }
      }
    }
  }
}
</style>
